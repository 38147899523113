<template>
  <div class="CmsContentsFiledsList">
    <div class="contentsWidth mx-auto mt-4">

      <div class="">
        <p class="title mb-0">申請管理 - 一覧</p>
        <hr class="title">
      </div>

      <div id="serchWrap" class="mt-4">
        <div class="bold">絞り込み条件</div>
        <div class="search_area flex flexCenter">
          <div class="searchShort inlineBlock searchSpace">
            <div>年度</div>
            <div>
              <b-form-select
                v-model="search.year"
                :options="yearList">
              </b-form-select>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>助成プログラム</div>
            <div>
              <b-form-select
                v-model="search.series"
                :options="seriesList">
                <template v-slot:first>
                  <option :value="null">-- 選択してください。 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>学生名</div>
            <div>
              <b-form-input
                v-model="search.name"
                placeholder="入力してください。"/>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>申請番号</div>
            <div>
              <b-form-input
                v-model="search.code"
                placeholder="入力してください。"/>
            </div>
          </div>
          <div class="searchMiddle inlineBlock">
            <div>ステータス</div>
            <div>
              <b-form-select
                v-model="search.status"
                :options="statusList">
                <template v-slot:first>
                  <option :value="''">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
        </div>
      </div>

      <div
        class="my-2 text-center">
        <p class="mb-1">新しく申請を作成する場合は、助成プログラムを選択後、
          <b-link
            v-show="search.series && isPublic"
            :to="`/university/applications/create/${search.year}/${search.series}`"
            class="newBtn active">新規申請</b-link>
          <span v-show="!search.series || !isPublic" class="newBtn no-active">新規申請</span>
        から申請フォームに移動してください。</p>
        <p class="mb-1 notice" v-if="this.search.series && !this.selectedApptype">この助成プログラムは現在準備中です。</p>
        <p class="mb-1 notice" v-if="!isPublic && this.selectedApptype">この助成プログラムは公開前なので、入力フォームの閲覧はできません。公開は{{isPublicText}}からです。</p>
        <p class="mb-1 notice" v-if="isOutTerm && this.selectedApptype">申請期間外のため、申請はできません。申請期間は{{outTermText.from}}から{{outTermText.to}}までです。</p>
      </div>

      <div class="contentsWidth mt-2">
        <div class="flex flex-between my-2">
          <b-pagination
            class="mb-1"
            aria-controls="fieldTable"
            :value="currentPage"
            @input="setCurrentPage($event)"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                :value="perPage"
                @change="setPerPage"
                :options="pageOptions"
              />
            </div>
            <div class="ml-4 inlineBlock">
              <b-link
                v-if="search.series && isPublic"
                :to="`/university/applications/create/${search.year}/${search.series}`"
                class="newBtn active">新規申請</b-link>
              <span v-else class="newBtn no-active">新規申請</span>
            </div>
          </div>
        </div>

        <b-table striped hover
          id='fieldTable'
          table-class="uniAppListTable"
          thead-class="tableHead"
          tbody-tr-class="dataWrap"
          :items="appList"
          :fields="header"
          :filter="search"
          :filter-function="filtering"
          @filtered="onFiltered"
          show-empty
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template v-slot:emptyfiltered="scope">
            <div class="flex flexCenter">条件に一致するデータがありません。</div>
          </template>
          <!-- kanaでソートをするが、表示を漢字名 -->
          <template v-slot:cell(kana)="row">
            {{row.item.name}}
          </template>
          <template v-slot:cell(btn)="row">
            <b-link :to="'/university/applications/edit/'+row.item.id">
              詳細
            </b-link>
          </template>
        </b-table>

        <div class="flex flex-between my-2" v-if="totalRows > 0">
          <b-pagination
            class="mb-1"
            aria-controls="fieldTable"
            :value="currentPage"
            @input="setCurrentPage($event)"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                :value="perPage"
                @change="setPerPage"
                :options="pageOptions"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import moment from 'moment';
import api from '@/modules/api';
import CONST_STATUS from '@/constants/appStatus';
// import download from '@/modules/download';

export default {
  name: 'UniversityApplicationsList',
  data() {
    return {
      rawSeriesList: [],
      statusList: CONST_STATUS.UNI_APP_STATUS_OPTION,
      dbAppList: [],
      header: [
        { key: 'kana', label: '学生名', sortable: true },
        { key: 'belongs', label: '大学名', sortable: true },
        { key: 'apptype', label: '助成プログラム', sortable: true },
        { key: 'code', label: '申請番号', sortable: true },
        { key: 'bunriText', label: '文理区分', sortable: true },
        { key: 'statusText', label: 'ステータス', sortable: true },
        { key: 'btn', label: '申請書', sortable: false },
      ],
      pageOptions: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
        // { value: 0, text: 'すべて' },
      ],
      yearList: [],
      selectedAppIdList: [],
      filterdIdList: [],
      bunriText: ['', '文', '理'],
      isInit: true,
      oldPage: 1,
    };
  },
  methods: {
    // フィルター用 search変更時に各行に対して走る
    filtering(lineData, search) {
      const nameSort = search.name === '' || lineData.name.includes(search.name);
      const seriesSort = search.series === null || lineData.seriesId === Number(search.series);
      const codeSort = search.code === '' || (lineData.code && lineData.code.includes(search.code));
      const statusSort = search.status === '' || lineData.status === Number(search.status);
      return nameSort && seriesSort && codeSort && statusSort;
    },
    onFiltered(filteredItems) {
      this.$store.commit('uniApplicationSearch/setTotalRows', filteredItems.length);
      this.filterdIdList = filteredItems.map((data) => {
        return data.id;
      });
      if (this.isInit) {
        this.$store.commit('uniApplicationSearch/setCurrentPage', this.oldPage);
      } else {
        this.$store.commit('uniApplicationSearch/setCurrentPage', 1);
      }
    },
    async fetchList() {
      this.$store.dispatch('page/onLoading');

      const param = {
        year: this.search.year,
      };

      const response = await api.send('/api/uni/applications/list', param)
        .catch((err) => {
          this.$store.dispatch('page/offLoading');
          console.log(err);
        });
      this.yearList = response.data.yearList;
      this.rawSeriesList = response.data.seriesList;
      this.dbAppList = response.data.list;
      if (this.search.year === null) {
        this.setFilter('year', this.yearList[0] || null);
      }
      this.$store.dispatch('page/offLoading');
    },
    setFilter(key, value) {
      const param = { key, value };
      this.$store.commit('uniApplicationSearch/setFilter', param);
    },
    setCurrentPage(page) {
      this.$store.commit('uniApplicationSearch/setCurrentPage', page);
    },
    setPerPage(page) {
      this.$store.commit('uniApplicationSearch/setPerPage', page);
    },
    setTotalRows(value) {
      this.$store.commit('uniApplicationSearch/setTotalRows', value);
    },
  },
  computed: {
    seriesList() {
      const result = {};
      this.rawSeriesList.forEach((series) => {
        result[series.id] = series.name;
      });
      return result;
    },
    appList() {
      const appList = this.dbAppList.map((application) => {
        let name = '';
        let kana = '';
        let belongs = '';
        if (application.ans_name && application.ans_kana) {
          application.ans_name.forEach((data) => {
            name += `${data.field_answer_text} `;
          });
          application.ans_kana.forEach((data) => {
            kana += `${data.field_answer_text} `;
          });
        }
        if (application.ans_belongs) {
          belongs = application.ans_belongs.field_answer_text;
        }
        return {
          id: application.id,
          apptypeId: application.application_type_id,
          apptype: application.apptype.name,
          name,
          kana,
          belongs,
          bunri: application.bunri,
          bunriText: this.bunriText[application.bunri],
          year: application.apptype.year,
          code: application.code,
          status: application.status,
          statusText: CONST_STATUS.APP_STATUS_TEXTS[application.status],
          seriesId: application.series.series_id,
        };
      });
      return appList;
    },
    search() {
      return this.$store.state.uniApplicationSearch.search;
    },
    currentPage() {
      return this.$store.state.uniApplicationSearch.currentPage;
    },
    perPage() {
      return this.$store.state.uniApplicationSearch.perPage;
    },
    totalRows() {
      return this.$store.state.uniApplicationSearch.totalRows;
    },
    nowPageCount() {
      if (this.totalRows === 0) {
        return 0;
      }
      let maxPage = Math.floor(this.totalRows / this.perPage);
      // 上記計算にあまりがあれば、ページ数をプラス1
      const mod = this.totalRows % this.perPage;
      let lastPageItemNum;
      if (mod !== 0) {
        maxPage += 1;
        lastPageItemNum = mod;
      } else {
        lastPageItemNum = this.perPage;
      }
      // 最終ページ以外は、現在の表示件数設定の値
      if (this.currentPage < maxPage) {
        return this.perPage;
      }
      // 最終ページが表示件数ぴったりの場合以外は、端数を表示
      return lastPageItemNum;
    },
    selectedApptype() {
      if (!this.search.series || !this.search.year || this.rawSeriesList.length === 0) {
        return null;
      }
      const selectedSeries = this.rawSeriesList.find((series) => {
        return Number(this.search.series) === series.id;
      });
      return selectedSeries.apptypeList.find((apptype) => {
        return apptype.year === Number(this.search.year);
      });
    },
    isOutTerm() {
      if (!this.selectedApptype) {
        return false;
      }
      return !moment().isBetween(this.selectedApptype.open_datetime, this.selectedApptype.close_datetime);
    },
    isPublic() {
      if (!this.selectedApptype) {
        return false;
      }
      return moment().isAfter(this.selectedApptype.application_announcement_datetime);
    },
    isPublicText() {
      if (this.isPublic || !this.selectedApptype) {
        return '';
      }
      return moment(this.selectedApptype.application_announcement_datetime).format('YYYY/MM/DD HH:mm');
    },
    outTermText() {
      if (!this.isOutTerm || !this.selectedApptype) {
        return {};
      }
      return {
        from: moment(this.selectedApptype.open_datetime).format('YYYY/MM/DD HH:mm'),
        to: moment(this.selectedApptype.close_datetime).format('YYYY/MM/DD HH:mm'),
      };
    },
  },
  watch: {
    'search.year': async function () {
      this.$store.dispatch('page/onWaiting');
      await this.fetchList();
      this.$store.dispatch('page/offWaiting');
    },
  },
  // ロード画面
  async created() {
    this.oldPage = this.currentPage;
    await this.fetchList();
    this.isInit = false;
  },
};
</script>

<style>
  .uniAppListTable thead th:nth-of-type(1) {
    width: 250px !important;
  }
  .uniAppListTable thead th:nth-of-type(5) {
    width: 120px !important;
  }
  .uniAppListTable thead th:nth-of-type(6) {
    width: 120px !important;
  }
  .uniAppListTable thead th:nth-of-type(7) {
    width: 100px !important;
  }
  .CmsContentsFiledsList .uniAppListTable .tableHead {
    background: #120f69 !important;
  }
</style>

<style scoped>

  #serchWrap input, #serchWrap select {
    height: 50px;
  }

  #NumberWrap {
    height: 50px;
  }

  .search_area {
    background: #DDD;
    padding: 10px;
    margin-bottom: 30px;
  }

  .searchMiddle {
    width: 205px;
  }

  .searchShort {
    width: 135px;
  }

  .searchSpace {
    margin-right: 15px;
  }

  #popover {
    cursor: pointer;
    color: #FFF !important;
    text-decoration: underline;
  }

  .link {
    color: #0A8EA7 !important;
    cursor: pointer;
  }

  .link:hover {
    text-decoration: underline;
  }

  .err-wrap>p, .notice{
    color: #dc3545;
  }

  .newBtn {
    border-radius: 6px;
    width: 80px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    padding: 2px 1px 1px 1px;
  }
  .newBtn.no-active {
    color :#CCC !important;
    border: 1px solid #4b58ad;
    background-color: #4b58ad;
  }
  .newBtn.active {
    color :#FFF !important;
    border: 1px solid #293CB0;
    background-color: #293CB0;
  }
  .newBtn.active:hover {
    text-decoration: none;
    cursor: pointer;
  }
</style>
